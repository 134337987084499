import Papa from "papaparse";
import React, { useState, useEffect } from "react";
import "./bulk-room-create.scss";

import ContactApi from "../../../../../../api/contact-api";
import ThreadApi from "../../../../../../api/thread-api";
import ContactHelper from "../../../../../../entities/contact/contact-helper";
import { EventEmitter } from "../../../../../../shared/utils/event-emitter";
import Events from "../../../../../../const/events";

const BulkRoomCreateModal = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [processedData, setProcessedData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [manualContact1Name, setManualContact1Name] = useState("");
  const [manualContact1Mobile, setManualContact1Mobile] = useState("");
  const [manualContact2Name, setManualContact2Name] = useState("");
  const [manualContact2Mobile, setManualContact2Mobile] = useState("");
  const [manualProcessing, setManualProcessing] = useState(false);
  const [manualResult, setManualResult] = useState(null);

  const validateMobileNumber = (mobile) => {
    let value = mobile.trim();

    // Remove all special characters except "+"
    value = value.replace(/[^+\d]/g, "");

    if (value === "") {
      return { isValid: false, error: "Phone number is required." };
    }

    // Ensure it starts with "+"
    if (!value.startsWith("+")) {
      return {
        isValid: false,
        error:
          "Phone number must start with a country code (e.g., +1 for USA).",
      };
    }

    // Extract numeric part (excluding "+")
    const numericPart = value.replace("+", "");

    // Check minimum length
    if (numericPart.length < 10) {
      return {
        isValid: false,
        error:
          "Phone number must have at least 10 digits (excluding country code).",
      };
    }

    // Check maximum length (adjust as needed)
    if (numericPart.length > 15) {
      return {
        isValid: false,
        error: "Phone number must not exceed 15 digits.",
      };
    }

    return { isValid: true, formattedNumber: value };
  };

  const handleManualRoomCreation = async () => {
    if (
      !manualContact1Name ||
      !manualContact1Mobile ||
      !manualContact2Name ||
      !manualContact2Mobile
    ) {
      setManualResult({
        success: false,
        message: "Please enter both contacts' names and mobile numbers.",
      });
      return;
    }

    const validation1 = validateMobileNumber(manualContact1Mobile);
    const validation2 = validateMobileNumber(manualContact2Mobile);

    if (!validation1.isValid || !validation2.isValid) {
      setManualResult({
        success: false,
        message: validation1.error
          ? `First contact: ${validation1.error}`
          : `Second contact: ${validation2.error}`,
      });
      return;
    }

    setManualProcessing(true);
    setManualResult(null);

    try {
      const contact1Id = await processContact(
        manualContact1Name,
        validation1.formattedNumber
      );
      const contact2Id = await processContact(
        manualContact2Name,
        validation2.formattedNumber
      );

      const roomResult = await createRoom(
        contact1Id,
        contact2Id,
        manualContact1Name,
        manualContact2Name
      );

      setManualResult({
        success: roomResult.success,
        message: roomResult.success
          ? "✅ Room Created Successfully"
          : roomResult.message,
      });

      setShowResults(true);

      // ✅ Reset form if room was successfully created
      if (roomResult.success) {
        setManualContact1Name("");
        setManualContact1Mobile("");
        setManualContact2Name("");
        setManualContact2Mobile("");
      }
    } catch (error) {
      setManualResult({
        success: false,
        message: "❌ Error: Could not create room.",
      });
    } finally {
      setManualProcessing(false);
    }
  };

  const processContact = async (name, phone) => {
    try {
      // First check if contact exists
      const { data: checkResult } =
        await ContactApi.private.checkIfContactExists({ phone });

      console.log("checkResult :>> ", checkResult);

      // If contact exists, return the contact ID directly
      if (checkResult?.data?._id) {
        return checkResult.data._id;
      }

      // If no valid contact ID is found, throw an error
      throw new Error("Contact check returned an invalid response.");
    } catch (error) {
      console.error("Error checking contact:", error);

      // If the error indicates that the contact doesn't exist (404), create a new one
      if (error?.response?.status === 404) {
        try {
          const { contactId } = await ContactHelper.createNewContact({
            name,
            phone,
          });

          if (contactId) {
            return contactId;
          }

          throw new Error("Failed to create new contact.");
        } catch (createError) {
          console.error("Error creating contact:", createError);
          throw new Error(`Contact creation failed: ${createError.message}`);
        }
      }

      // If it's a different error, rethrow it
      throw new Error(`Contact processing failed: ${error.message}`);
    }
  };

  const getFirstName = (fullName) => {
    return fullName.split(" ")[0];
  };

  const createRoom = async (
    contact1Id,
    contact2Id,
    contact1Name,
    contact2Name
  ) => {
    try {
      const roomTitle = `${getFirstName(contact1Name)}+${getFirstName(
        contact2Name
      )}`;

      const response = await ThreadApi.private.createThread({
        type: "room",
        title: roomTitle,
        _users: [],
        _contacts: [contact1Id, contact2Id],
      });

      return { success: true, data: response?.data };
    } catch (error) {
      if (
        error.response?.data?.message ===
        "one or more contacts already have an active thread"
      ) {
        return { success: false, message: "EXISTS" };
      }
      throw error;
    }
  };

  const processCSV = async () => {
    if (!selectedFile) return;
    setIsProcessing(true);
    setProcessedData([]); // Clear previous results

    try {
      const csvData = await new Promise((resolve, reject) => {
        Papa.parse(selectedFile, {
          complete: (results) => {
            const filteredData = results.data.filter((row) =>
              Object.values(row).some((value) => value.trim() !== "")
            );
            resolve(filteredData);
          },
          error: (error) => reject(error),
          header: true,
          skipEmptyLines: true,
        });
      });

      console.log("csvData :>> ", csvData);

      const results = [];
      for (const row of csvData) {
        try {
          const validation1 = validateMobileNumber(row["Contact1 Mobile"]);
          const validation2 = validateMobileNumber(row["Contact2 Mobile"]);

          if (!validation1.isValid || !validation2.isValid) {
            results.push({
              contact1: {
                name: row["Contact1 Name"],
                mobile: row["Contact1 Mobile"],
              },
              contact2: {
                name: row["Contact2 Name"],
                mobile: row["Contact2 Mobile"],
              },
              status: {
                success: false,
                message: validation1.isValid
                  ? `Second contact: ${validation2.error}`
                  : `First contact: ${validation1.error}`,
              },
            });
            setProcessedData([...results]);
            continue;
          }

          const contact1Id = await processContact(
            row["Contact1 Name"],
            validation1.formattedNumber
          );
          const contact2Id = await processContact(
            row["Contact2 Name"],
            validation2.formattedNumber
          );

          const roomResult = await createRoom(
            contact1Id,
            contact2Id,
            row["Contact1 Name"],
            row["Contact2 Name"]
          );

          results.push({
            contact1: {
              name: row["Contact1 Name"],
              mobile: validation1.formattedNumber,
            },
            contact2: {
              name: row["Contact2 Name"],
              mobile: validation2.formattedNumber,
            },
            status: {
              success: roomResult.success,
              message: roomResult.success ? "CREATED" : roomResult.message,
            },
          });

          setProcessedData([...results]);
        } catch (error) {
          results.push({
            contact1: {
              name: row["Contact1 Name"],
              mobile: row["Contact1 Mobile"],
            },
            contact2: {
              name: row["Contact2 Name"],
              mobile: row["Contact2 Mobile"],
            },
            status: { success: false, message: error.message || "INVALID" },
          });
          setProcessedData([...results]);
        }
      }

      setShowResults(true);
    } catch (error) {
      console.error("Error processing CSV:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleDownloadSample = () => {
    const sampleData =
      "Contact1 Name,Contact1 Mobile,Contact2 Name,Contact2 Mobile\nJohn Doe,1234567890,Jane Doe,9876543210";
    const blob = new Blob([sampleData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "sample_contacts.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    const _registerEvents = () => {
      EventEmitter.subscribe(Events.SHOW_CSV_ROOM_IMPORT_POPUP, () => {
        setPopupVisible(true);
      });
      EventEmitter.subscribe(Events.HIDE_CSV_ROOM_IMPORT_POPUP, () => {
        setPopupVisible(false);
      });
    };
    const _unregisterEvents = () => {
      EventEmitter.cancelAll(Events.SHOW_CSV_ROOM_IMPORT_POPUP);
      EventEmitter.cancelAll(Events.HIDE_CSV_ROOM_IMPORT_POPUP);
    };
    _registerEvents();
    return () => {
      _unregisterEvents();
    };
  }, []);

  if (!popupVisible) {
    return null;
  }

  console.log("selectedFile :>> ", selectedFile);

  return (
    <div className="modal-wrapper">
      <div
        className="modal fade show"
        id="newCSVFileUploadModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="newCSVFileUploadModalTitle"
        aria-hidden={false}
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header py-3">
              <h6 className="modal-title m-0" id="newCSVFileUploadModalTitle">
                Connect Contacts
              </h6>
              {showResults ? (
                <button
                  type="button"
                  className="btn"
                  onClick={() => {
                    setShowResults(false);
                    setSelectedFile(null);
                    setProcessedData([]);
                    setPopupVisible(false);
                  }}
                >
                  <i className="fa fa-times"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setSelectedFile(null);
                    setPopupVisible(false);
                  }}
                ></button>
              )}
            </div>

            <div className="modal-body">
              <div className="modalInner">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="">First contact</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter contact name"
                      value={manualContact1Name}
                      onChange={(e) => setManualContact1Name(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Enter contact number"
                      value={manualContact1Mobile}
                      onChange={(e) => setManualContact1Mobile(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">Second contact</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter contact name"
                      value={manualContact2Name}
                      onChange={(e) => setManualContact2Name(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Enter contact number"
                      value={manualContact2Mobile}
                      onChange={(e) => setManualContact2Mobile(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <div className="buttons text-end mt-3">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={handleManualRoomCreation}
                        disabled={manualProcessing}
                      >
                        {manualProcessing
                          ? "Processing..."
                          : "Start a new chat"}
                      </button>
                    </div>
                  </div>
                  {manualResult && (
                    <div className="col-12 mt-3">
                      <div
                        className={`alert ${
                          manualResult.success
                            ? "alert-success"
                            : "alert-danger"
                        }`}
                      >
                        {manualResult.success
                          ? "✅ Room Created Successfully"
                          : `⚠️ ${manualResult.message}`}
                      </div>
                    </div>
                  )}
                </div>

                <h4 className="text-center m-0 my-3">OR</h4>

                <div className="selectCSVFileWrapper">
                  <div className="custom-file csvUpload">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="inputGroupFile01"
                      accept=".csv"
                      onChange={handleFileUpload}
                      style={{ opacity: 0, position: "absolute" }} // Add this style
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="inputGroupFile01"
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }} // Add this style
                    >
                      {selectedFile?.name || "Upload CSV file"}
                    </label>
                  </div>
                </div>

                <div className="sampleCSVWrapper mt-3">
                  <span>Sample CSV</span>
                  <div
                    className="btn btn-success btn-sm"
                    onClick={handleDownloadSample}
                  >
                    <i className="fa fa-download me-1"></i> Download
                  </div>
                </div>
              </div>

              {showResults && (
                <div className="modalInner modalRight">
                  <div className="tableWrapper">
                    <table className="table table-responsive">
                      <colgroup>
                        <col width="200px" />
                        <col width="200px" />
                        <col width="200px" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Phone 1</th>
                          <th>Phone 2</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {processedData.map((row, index) => (
                          <tr key={index}>
                            <td>
                              {row.contact1.mobile} ({row.contact1.name})
                            </td>
                            <td>
                              {row.contact2.mobile} ({row.contact2.name})
                            </td>
                            <td>
                              <div className="p-0">
                                {row.status?.success ? (
                                  <span className="badge bg-success">
                                    ✅ Room Created
                                  </span>
                                ) : row.status?.message === "EXISTS" ? (
                                  <span className="badge border border-blue text-blue">
                                    🔴 Already in a room
                                  </span>
                                ) : (
                                  <span className="badge bg-danger">
                                    ⚠️ {row.status.message}
                                  </span>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>

            <div className="modal-footer d-flex align-items-center justify-content-between">
              {!showResults ? (
                <>
                  <button
                    type="button"
                    className="btn btn-soft-danger btn-sm"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setSelectedFile(null);
                      setPopupVisible(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    disabled={!selectedFile}
                    onClick={processCSV}
                  >
                    {isProcessing ? "Processing..." : "Submit"}
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    setShowResults(false);
                    setSelectedFile(null);
                    setProcessedData([]);
                  }}
                >
                  Upload Again
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkRoomCreateModal;
